<template>
    <div v-if="Object.keys(footer).length !== 0">
        <!-- Event Footer menu -->
        <!-- <div class="footer-navbar">
            <div class="container">
                <div class="left">
                    <figure class="footer-logo">
                        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_3.0/images/Dooprime.svg">
                    </figure>
                    <template v-for="(footer_menu_value, footer_menu_key) in footer['footer_menu']">
                        <div v-if="String(footer_menu_key) == 'contact' || String(footer_menu_key) == 'email' || String(footer_menu_key) == 'social_media'"
                            :class="'footer-' + footer_menu_key">

                            <p v-if="footer['footer_menu'][footer_menu_key][0]['is_title'] == '1'">{{
                                footer['footer_menu'][footer_menu_key][0]['translate'] }}</p>

                            <ul v-if="String(footer_menu_key) == 'contact' || String(footer_menu_key) == 'email'">
                                <template v-for="(value, key) in footer['footer_menu'][footer_menu_key]">
                                    <li
                                        v-if="String(footer_menu_key) == 'contact' && value['is_title'] != '1' || String(footer_menu_key) == 'email' && value['is_title'] != '1'">
                                        {{
                                            value['translate'] }}</li>
                                </template>
                            </ul>
                            <div v-if="String(footer_menu_key) == 'social_media'" class="social-media">
                                <template v-for="(value, key) in footer['footer_menu'][footer_menu_key]">
                                    <a :href="value['link']" target="_blank">
                                        <img :src="imageUrl + value['translate'].toLowerCase() + '.svg'" />
                                    </a>
                                </template>
                            </div>
                        </div>
                    </template>
                </div>

            </div>
        </div> -->
        <div id="footer-top">
            <div class="footer-top-wrapper container">
                <div class="section-about">
                    <img class="about-logo" alt="logo DooPrime"
                        src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ib_3.0/images/Dooprime.svg">
                    <div class="about-social-container">
                        <template v-for="(value, key) in footer['footer_menu']['social_media']">
                            <a v-if="value['translate'] != ' ' && value['translate'] != '' && value['translate'] != null"
                                :href="value['link']" target="_blank">
                                <img :src="imageUrl + value['translate'].toLowerCase() + '.png'" />
                            </a>
                        </template>
                    </div>
                </div>
                <div class="contact-container phone-1">
                    <template v-for="(value, key) in footer['footer_menu']['contact']">
                        <h4 class="contact-title upper" v-if="value['is_title'] == '1'">{{ value['translate'] }}</h4>
                        <p v-else class="contact-item">{{ value['translate'] }}</p>
                    </template>
                </div>
                <div class="contact-container email">
                    <template v-for="(value, key) in footer['footer_menu']['email']">
                        <h4 cclass="contact-title" v-if="value['is_title'] == '1'">{{ value['translate'] }}</h4>
                        <p v-else class="contact-item">{{ value['translate'] }}</p>
                    </template>
                </div>
                <div class="mobile-social-container">
                    <template v-for="(value, key) in footer['footer_menu']['social_media']">
                        <a v-if="value['translate'] != ' ' && value['translate'] != '' && value['translate'] != null"
                            :href="value['link']" target="_blank">
                            <img :src="imageUrl + value['translate'].toLowerCase() + '.png'" />
                        </a>
                    </template>
                </div>
            </div>
        </div>

        <div v-if="Object.keys(footer).length !== 0" class="footer-compliance">
            <div class="container">
                <div v-for="(compliance_menu_value, compliance_menu_key) in footer['compliance_menu']">

                    <template v-if="String(compliance_menu_key) === 'risk_disclosure'"
                        v-for="(value, key) in footer['compliance_menu'][compliance_menu_key]">
                        <p v-if="value['is_title'] == '1'" class='compliance-title'>{{ value['translate'] }}</p>
                        <p v-else class='ip-detection'>{{ value['translate'] }}</p>
                    </template>

                    <div v-if="String(compliance_menu_key) === 'doo_entities'"
                        v-for="(value, key) in footer['compliance_menu'][compliance_menu_key]">

                        <div class='compliance-content'>
                            <p v-if="key == 0">{{ value['translate'] }}</p>
                            <p v-else>{{ value['translate'] }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="footer-accordion is-hidden-desktop">
            <div class="container">
                <template v-for="(compliance_menu_value, compliance_menu_key) in footer['compliance_menu']">
                    <template
                        v-if="String(compliance_menu_key) !== 'risk_disclosure' && String(compliance_menu_key) !== 'doo_entities' && String(compliance_menu_key) !== 'compliance_document' && String(compliance_menu_key) !== 'copyright' && String(compliance_menu_key) !== 'doo_entities_notice'"
                        v-for="(value, key) in footer['compliance_menu'][compliance_menu_key]">

                        <h6 v-if="value['is_title'] == '1' && String(compliance_menu_key) === 'brand_and_entity_notice'"
                            :class="'ahead open ' + value['id']" @click="toggle(value['id'])">
                            {{ value['translate'] }}</h6>
                        <h6 v-else-if="value['is_title'] == '1'" :class="'ahead ' + value['id']"
                            @click="toggle(value['id'])">{{
                                value['translate'] }}
                        </h6>

                        <div v-if="compliance_menu_value[0]['is_title'] == '1' && String(compliance_menu_key) === 'doo_entities_notice'"
                            class='abody footer-entities'>
                            <div class="entity">
                                <template v-for="(value1, key1) in compliance_menu_value">
                                    <h6 v-if="value1['is_sub_title'] == '1'" class='entity-title'>{{ value1['translate'] }}
                                    </h6>
                                    <div v-else-if="value1['is_title'] != '1' && value1['is_sub_title'] != '1'"
                                        class="entity-content">
                                        <p>{{ value1['translate'] }}</p>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div v-else class="abody">
                            <template v-for="(value1, key1) in compliance_menu_value">
                                <p v-if="value1['is_title'] != '1' && value1['translate'] != 'Doo_Entities_Notice'">{{
                                    value1['translate'] }}</p>
                                <template v-if="value1['translate'] == 'Doo_Entities_Notice'">
                                    <div class="entity">
                                        <template
                                            v-for="(doo_entities_notice_value, doo_entities_notice_key) in footer['compliance_menu']['doo_entities_notice']">

                                            <h6 v-if="doo_entities_notice_value['is_sub_title'] == '1'"
                                                class='entity-title'>{{
                                                    doo_entities_notice_value['translate'] }}
                                            </h6>
                                            <div v-else-if="doo_entities_notice_value['is_title'] != '1' && doo_entities_notice_value['is_sub_title'] != '1'"
                                                class="entity-content">
                                                <p>{{ doo_entities_notice_value['translate'] }}</p>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </template>
                        </div>

                    </template>
                </template>
            </div>
        </div>
        <!-- here -->
        <div class=" footer-tab is-hidden-touch">
            <div class="container">
                <div class="tab-links is-flex">
                    <template v-for="(compliance_menu_value, compliance_menu_key) in footer['compliance_menu']">
                        <template
                            v-if="String(compliance_menu_key) !== 'risk_disclosure' && String(compliance_menu_key) !== 'doo_entities' && String(compliance_menu_key) !== 'compliance_document' && String(compliance_menu_key) !== 'copyright' && String(compliance_menu_key) !== 'doo_entities_notice'"
                            v-for="(value, key) in footer['compliance_menu'][compliance_menu_key]">
                            <span
                                v-if="value['is_title'] == '1' && String(compliance_menu_key) === 'brand_and_entity_notice'"
                                :class="'tab-link active ' + value['id']">{{ value['translate']
                                }}</span>
                            <span v-else-if="value['is_title'] == '1'" :class="'tab-link ' + value['id']">{{
                                value['translate'] }}</span>
                        </template>
                    </template>
                </div>

                <template v-for="(compliance_menu_value, compliance_menu_key) in footer['compliance_menu']">

                    <template
                        v-if="String(compliance_menu_key) !== 'risk_disclosure' && String(compliance_menu_key) !== 'doo_entities' && String(compliance_menu_key) !== 'compliance_document' && String(compliance_menu_key) !== 'copyright' && String(compliance_menu_key) !== 'doo_entities_notice'">

                        <template
                            v-if="compliance_menu_value[0]['is_title'] == '1' && String(compliance_menu_key) === 'doo_entities_notice'">
                            <div class='tab-content footer-entities active'>

                                <template v-for="(value, key) in footer['compliance_menu'][compliance_menu_key]">
                                    <div v-if="value['is_title'] != '1' && value['is_sub_title'] == '1'" class="entity">
                                        <h6 v-if="value['is_sub_title'] == '1' && value['is_title'] != '1'"
                                            class='entity-title'>{{ value['translate']
                                            }}</h6>

                                        <div class='entity-content'>
                                            <template
                                                v-for="(value1, key1) in footer['compliance_menu'][compliance_menu_key]">
                                                <p
                                                    v-if="key1 > key && value1['is_title'] != '1' && value1['is_sub_title'] != '1' && key1 < getNextNumber(key)">
                                                    {{ value1['translate'] }}
                                                </p>

                                            </template>
                                        </div>

                                    </div>
                                </template>
                            </div>
                        </template>
                        <template v-else>
                            <div
                                :class="(String(compliance_menu_key) === 'brand_and_entity_notice') ? 'tab-content active' : 'tab-content'">
                                <template v-for="(value, key) in footer['compliance_menu'][compliance_menu_key]">
                                    <p v-if="value['is_title'] != '1' && value['translate'] != 'Doo_Entities_Notice'">
                                        {{
                                            value['translate'] }}</p>
                                    <template v-if="value['translate'] == 'Doo_Entities_Notice'">
                                        <div class='footer-entities active'>

                                            <div v-if="value['is_title'] != '1' && value['is_sub_title'] == '1'"
                                                class="entity"
                                                v-for="(value, key) in footer['compliance_menu']['doo_entities_notice']">
                                                <h6 v-if="value['is_sub_title'] == '1' && value['is_title'] != '1'"
                                                    class='entity-title'>{{ value['translate']
                                                    }}</h6>

                                                <div class='entity-content'>
                                                    <template
                                                        v-for="(value1, key1) in footer['compliance_menu']['doo_entities_notice']">
                                                        <p
                                                            v-if="key1 > key && value1['is_title'] != '1' && value1['is_sub_title'] != '1' && key1 < getNextNumber(key)">
                                                            {{ value1['translate'] }}
                                                        </p>

                                                    </template>
                                                </div>

                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </template>
                    </template>


                </template>

            </div>
        </div>
        <div class="footer-bottom-nav">
            <div class="container">
                <ul>
                    <template v-for="(compliance_menu_value, compliance_menu_key) in footer['compliance_menu']">
                        <template v-if="String(compliance_menu_key) === 'compliance_document'"
                            v-for="(value, key) in footer['compliance_menu'][compliance_menu_key]">
                            <li><a :href="value['link']" target="_blank">{{ value['translate'] }}</a></li>
                        </template>
                    </template>
                </ul>
            </div>
        </div>
        <div class="footer-copyright">
            <div class="container">
                <template v-for="(compliance_menu_value, compliance_menu_key) in footer['compliance_menu']">
                    <p v-if="String(compliance_menu_key) === 'copyright'">{{
                        compliance_menu_value[0]['translate']
                    }}</p>
                </template>
            </div>
        </div>
    </div>
</template>
  
<script lang="ts">
import { footer } from '@/api/footer'
import { getLocation } from '@/api/location'
import footerJson from "../../assets/cms/footer";

export default {
    data() {
        return {
            footer: [],
            complianceToggle: '',
            imageUrl: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo-prime-webiste/image/social_media/",
            dooEntitiesNotice: [],
        }
    },


    methods: {
        async getFooter() {
            const location = await getLocation()
            let lang = window.location.pathname.split('/')[1];
            if (lang == '') {
                lang = 'sc';
            }

            const params = {
                entities: "dooprime",
                country_code: location.iso_country_code,
                lang: lang,
                footer_menu: "1",
                compliance: "1",
                dynamic_url: "1",
            }

            const { data } = await footer(params);

            if (data.code == 0) {
                this.footer = data.data

            } else {
                this.footer = footerJson.data
            }
            const dooEntitiesNoticeList = this.footer['compliance_menu']['doo_entities_notice'];
            for (let index = 0; index < dooEntitiesNoticeList.length; index++) {
                const element = dooEntitiesNoticeList[index];
                if (element.is_sub_title == 1) {
                    this.dooEntitiesNotice.push(index)
                }
            }
            this.dooEntitiesNotice.push(dooEntitiesNoticeList.length)

        },
        getNextNumber(input) {

            const index = this.dooEntitiesNotice.indexOf(input);
            if (index !== -1 && index < this.dooEntitiesNotice.length - 1) {

                return this.dooEntitiesNotice[index + 1];
            }
            return 0; // Return null if input is not found or if it's the last element in the array.
        },

        async toggle(id) {

            let head = document.querySelectorAll('.ahead');

            for (let index = 0; index < head.length; index++) {
                const element = head[index];
                if (element.classList.contains(id)) {
                    element.classList.toggle("open");
                } else {
                    element.classList.remove("open");
                }
            }
        },

        initFooterTab() {
            const tabLinks = document.querySelectorAll('.footer-tab .tab-link');
            const tabContents = document.querySelectorAll('.footer-tab .tab-content');
            if (tabLinks.length) {
                tabLinks.forEach((tabLink, targetIndex) => {
                    tabLink.addEventListener('click', function () {
                        // toggle tabLinks
                        tabLinks.forEach((e, index) => {
                            if (index == targetIndex) e.classList.toggle('active');
                            else e.classList.remove('active');
                        });
                        // toggle tabContents
                        tabContents.forEach((tabContent, index) => {
                            if (index == targetIndex) tabContent.classList.toggle('active');
                            else tabContent.classList.remove('active');
                        });
                    }, false);
                })
            }
        }




    },
    mounted() {
        this.getFooter();
    },
    updated() {
        this.initFooterTab();
    },
}
</script>

<style scoped>
@media screen and (min-width:1024px) {
    .is-hidden-desktop {
        display: none !important;
    }
}

@media screen and (max-width:1023px) {
    .is-hidden-touch {
        display: none !important
    }
}

@media screen and (max-width: 1025px) {
    .footer-navbar .navbar-dropdown {
        position: static;
    }

    .footer-navbar .navbar-item,
    .footer-navbar .navbar-link {
        display: block;
    }
}

/* layout */
.footer-navbar {
    background-color: #f7f8f9;
    padding: 32px 0 24px;
}

.footer-navbar ul {
    padding-left: 0;
    list-style: none;
}

@media screen and (min-width: 1025px) {
    .footer-navbar {
        padding: 80px 0 58px;
    }

    .footer-navbar .container {
        display: flex;
    }

    .footer-navbar .left {
        flex: 0 0 35%;
    }

    .footer-navbar .right {
        flex: 1;
    }
}


/* logo */
.footer-logo {
    margin-bottom: 40px;
}

.footer-logo svg {
    width: 172px;
    height: 24px;
}

@media screen and (min-width: 1025px) {
    .footer-logo {
        margin-bottom: 88px;
    }

    .footer-logo svg {
        width: 271px;
        height: 38px;
    }
}

/* contact */
.footer-contact p {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 1.5px;
    color: #000018;
    margin-bottom: 16px;
}

.footer-contact>* {
    margin-bottom: 48px;
}

.footer-contact ul li {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    letter-spacing: 1.5px;
    color: #000018;
    margin-bottom: 16px;
}

@media screen and (min-width: 835px) and (max-width: 1024px) {
    .footer-contact {
        display: flex;
    }

    .footer-contact>* {
        flex: 1;
    }
}

@media screen and (min-width: 1025px) {
    .footer-contact>* {
        margin-bottom: 56px;
    }

    /* .footer-contact > *:last-of-type {
            margin-bottom: 180px;
        }  */
}

/* email */
.footer-email p {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 1.5px;
    color: #000018;
    margin-bottom: 16px;
}

.footer-email>* {
    margin-bottom: 48px;
}

.footer-email ul li {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    letter-spacing: 1.5px;
    color: #000018;
    margin-bottom: 16px;
}

@media screen and (min-width: 835px) and (max-width: 1024px) {
    .footer-email {
        display: flex;
    }

    .footer-email>* {
        flex: 1;
    }
}

@media screen and (min-width: 1025px) {
    .footer-email>* {
        margin-bottom: 56px;
    }

    .footer-email>*:last-of-type {
        margin-bottom: 180px;
    }
}

/* social media */
.footer-social_media .social-media {
    height: 24px;
}

.footer-social_media .qr-code {
    margin-bottom: 16px;
}

.footer-social_media .social-media a {
    display: inline-block;
    height: 24px;
    margin-right: 24px;
}

@media screen and (max-width: 1024px) {
    .footer-social_media .social-media {
        margin-bottom: 40px;
    }
}

@media screen and (min-width: 1025px) {
    .footer-social_media {
        /* position: absolute; */
        bottom: 0;
    }
}


/**
     * menu
     */
/* layout */
.footer-primary-menu .navbar-dropdown,
.footer-primary-menu .navbar-dropdown .navbar-item,
.footer-primary-menu .navbar-dropdown .navbar-dropdown {
    padding: 0;
}

.footer-primary-menu .navbar-item,
.footer-primary-menu .navbar-link {
    /* word-break: break-all; */
    /* white-space: initial; */
    white-space: break-spaces;
    text-decoration: none;
}

@media screen and (max-width: 1025px) {

    /* show / hide dropdown */
    .footer-primary-menu>.navbar-item.has-dropdown .navbar-dropdown {
        display: none;
    }

    .footer-primary-menu>.navbar-item.has-dropdown.active .navbar-dropdown {
        display: initial;
    }
}

@media screen and (min-width: 1025px) {
    .footer-primary-menu {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 16px;
    }

    .footer-primary-menu .navbar-dropdown {
        position: static;
        display: flex;
        flex-direction: column;
        background-color: transparent;
        border: 0;
        box-shadow: none;
    }
}

@media screen and (max-width: 1025px) {

    /* plus icon */
    .footer-primary-menu>.navbar-item.has-dropdown {
        position: relative;
    }

    .footer-primary-menu>.navbar-item.has-dropdown::before {
        content: "";
        width: 24px;
        height: 24px;
        position: absolute;
        top: 16px;
        right: 0;
        background: url("../../assets/images/footer/icon-common-add.svg") no-repeat scroll center / contain;
    }

    .rtl .footer-primary-menu>.navbar-item.has-dropdown::before {
        left: 0;
        right: inherit;
    }

    .footer-primary-menu>.navbar-item.has-dropdown.active::before {
        background-image: url("../../assets/images/footer/icon-common-subtract.svg");
    }
}

/* menu item */
.footer-primary-menu a.navbar-item:focus,
.footer-primary-menu a.navbar-item:hover,
.footer-primary-menu a.navbar-item.is-active,
.footer-primary-menu .navbar-link:focus,
.footer-primary-menu .navbar-link:hover,
.footer-primary-menu .navbar-link.is-active {
    background-color: transparent;
    color: #d91d22;
}

.footer-primary-menu .navbar-item.has-dropdown:focus .navbar-link,
.footer-primary-menu .navbar-item.has-dropdown:hover .navbar-link,
.footer-primary-menu .navbar-item.has-dropdown.is-active .navbar-link {
    background-color: transparent;
}

@media screen and (max-width: 1025px) {
    .footer-primary-menu>.navbar-item {
        border: 1px solid #e4e4e4;
        border-left: 0;
        border-right: 0;
        padding: 16px 0;
    }

    .footer-primary-menu>.navbar-item.has-dropdown.active {
        padding-bottom: 8px;
    }
}

@media screen and (min-width: 1025px) {
    .footer-primary-menu .navbar-item.has-dropdown {
        display: flex;
        flex-direction: column;
    }
}

/* level 1 */
.footer-primary-menu>.navbar-item>.navbar-link {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 1.5px;
    color: #000018;
    background-color: transparent !important;
    padding: 0 27px 0 0;
    text-decoration: none;
}

.rtl .footer-primary-menu>.navbar-item>.navbar-link {
    padding: 0 0 0 27px;
}

@media screen and (min-width: 1025px) {
    .footer-primary-menu>.navbar-item>.navbar-link {
        margin-bottom: 16px;
    }
}

@media screen and (max-width: 1024px) {

    .footer-primary-menu .navbar-item,
    .footer-primary-menu .navbar-link {
        white-space: initial;
    }
}

/* link */
.footer-primary-menu .navbar-item:not(.has-dropdown) {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
    letter-spacing: 1.5px;
    color: #000018;
    padding: 8px 0;
}

@media screen and (max-width: 1025px) {
    .footer-primary-menu>.navbar-item.has-dropdown>.navbar-dropdown>.navbar-item:not(.has-dropdown):first-of-type {
        margin-top: 8px;
    }
}

@media screen and (min-width: 1025px) {
    .footer-primary-menu .navbar-item:not(.has-dropdown) {
        margin-bottom: 16px;
        padding: 0;
    }

    .footer-primary-menu .navbar-item:not(.has-dropdown):last-of-type {
        margin-bottom: 0;
    }
}

/* level 2 */
.footer-primary-menu .navbar-dropdown .navbar-item.has-dropdown .navbar-link {
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 1.5px;
    color: #000000;
    border-left: 2px solid #d91d22;
    margin: 24px 0 8px;
    padding: 0 0 0 8px;
}

.rtl .footer-primary-menu .navbar-dropdown .navbar-item.has-dropdown .navbar-link {
    padding: 0 8px 0 0;
}

@media screen and (max-width: 1025px) {
    .footer-primary-menu>.navbar-item.has-dropdown>.navbar-dropdown>.navbar-item.has-dropdown:first-of-type>.navbar-link {
        margin-top: 16px;
    }
}

@media screen and (min-width: 1025px) {
    .footer-primary-menu .navbar-dropdown .navbar-item.has-dropdown .navbar-link {
        margin: 0 0 16px;
    }

    .footer-primary-menu .navbar-dropdown .navbar-item.has-dropdown:not(:first-of-type) .navbar-link {
        margin-top: 32px;
    }
}

.rtl .footer-primary-menu .navbar-dropdown .navbar-item.has-dropdown .navbar-link {
    border-left: inherit;
    border-right: 2px solid #d91d22;
}

/* svg use */
svg.footer_social_width_24 {
    width: 24px;
    height: 24px;
}

svg.footer_social_width_24 path {
    fill: #000018;
}

/* compliance risk disclosure */
.footer-compliance {
    background-color: #eff1f3;
    padding: 24px 0;
}

.footer-compliance .compliance-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 1.5px;
    color: #000018;
    margin-bottom: 14px;
}

.footer-compliance .ip-detection {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
    letter-spacing: 1.5px;
    color: #16a0ff;
    margin-bottom: 16px;
}

.footer-compliance .compliance-content p {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
    letter-spacing: 1.5px;
    color: #000018;
}

.footer-compliance .compliance-content p:not(:last-of-type) {
    margin-bottom: 12px;
}

@media screen and (min-width: 1025px) {
    .footer-compliance {
        padding: 40px 0 48px;
    }
}

/* compliance type */
.footer-entities .entity-title,
.footer-entities .entity-link a {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.75px;
}

.footer-entities .entity-content p {
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 1.75px;
    color: #36383b;
    margin-bottom: 16px;
}

.footer-entities .entity-content p:not(:last-of-type) {
    margin-bottom: 16px;
}

.footer-entities .entity-title {
    margin-bottom: 16px;
    color: #000018;
}

.footer-entities .entity-link a {
    color: #d91d22;
    margin-bottom: 8px;
}

@media screen and (min-width: 1025px) {
    .footer-tab {
        background-color: #eff1f3;
    }

    .footer-tab .tab-links {
        border: 1px solid #e4e4e4;
        border-left: 0;
        border-right: 0;
        padding: 16px 0;
    }

    .footer-tab .tab-link {
        cursor: pointer;
        position: relative;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.71;
        letter-spacing: 1.5px;
        color: #000018;
    }

    .footer-tab .tab-link:not(:last-of-type) {
        margin-right: 96px;
    }

    .rtl .footer-tab .tab-link:not(:last-of-type) {
        margin-left: 96px;
        margin-right: inherit;
    }

    .footer-tab .tab-link::after {
        content: "";
        position: absolute;
        bottom: -17.5px;
        left: calc(50% - 24px);
        width: 48px;
        height: 2px;
        background-color: #d91d22;
        display: none;
    }

    .footer-tab .tab-link.active::after {
        display: block;
    }

    .footer-tab .tab-content {
        display: none;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.71;
        letter-spacing: 1.5px;
        color: #000018;
        margin-top: 28px;
    }

    .footer-tab .tab-content.active {
        display: block;
    }

    .footer-tab .tab-content p:not(:last-of-type) {
        margin-bottom: 12px;
    }

    /* footer entities */
    .footer-entities {
        grid-template-columns: 1fr 1fr;
        grid-gap: 40px 24px;
    }

    .footer-entities.active {
        display: grid !important;
        margin-top: 44px;
    }

    .footer-entities .entity-title {
        margin-bottom: 24px;
        color: #000018;
    }

    .footer-entities .entity-content {
        margin-bottom: 24px;
    }

    .footer-entities .entity-content p:not(:last-of-type) {
        margin-bottom: 16px;
    }
}

@media screen and (max-width: 1025px) {
    .footer-accordion {
        background-color: #eff1f3;
    }

    .footer-accordion h6 {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 1.5px;
        color: #000018;
    }

    .rtl .footer-accordion h6 {
        letter-spacing: inherit;
    }

    .footer-accordion p {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.71;
        letter-spacing: 1.5px;
        color: #000018;
    }

    .footer-accordion .ahead {
        cursor: pointer;
        position: relative;
        border-top: 1px solid #e4e4e4;
        padding: 16px 27px 16px 0;
    }

    .rtl .footer-accordion .ahead {
        padding: 16px 0 16px 27px;
    }

    .footer-accordion .ahead.open+.abody {
        transition: all 0.2s ease-out;
        max-height: 3000px;
    }

    .footer-accordion .ahead::before {
        content: "";
        width: 24px;
        height: 24px;
        position: absolute;
        top: 16px;
        right: 0;
        background: url("../../assets/images/footer/icon-common-add.svg") no-repeat scroll center / contain;
    }

    .rtl .footer-accordion .ahead::before {
        left: 0;
        right: inherit;
    }

    .footer-accordion .ahead.open::before {
        background-image: url("../../assets/images/footer/icon-common-subtract.svg");
    }

    .footer-accordion .abody {
        overflow: hidden;
        transition: all 0.2s ease-out;
        max-height: 0;
        height: auto;
    }

    .footer-accordion .abody p {
        margin-bottom: 8px;
    }

    .footer-accordion .abody p:last-of-type {
        margin-bottom: 16px;
    }

    /* footer entities */
    .footer-entities .entity {
        margin-bottom: 32px;
    }

    .footer-entities .entity:first-of-type {
        margin-top: 16px;
    }
}

/* compliance document */
.footer-bottom-nav {
    background-color: #eff1f3;
    padding: 32px 0;
}

.footer-bottom-nav ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px;
    list-style-type: none;
}

.footer-bottom-nav ul li {
    position: relative;
    padding: 8px 16px;
}

.footer-bottom-nav ul li a {
    color: #36383b;
}

.footer-bottom-nav ul li a:hover {
    color: #d91d22;
}

.footer-bottom-nav ul li:not(:last-of-type)::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    width: 1px;
    height: 8px;
    background-color: #36383b;
}

@media screen and (max-width: 375px) {

    .rtl .footer-bottom-nav ul li:nth-child(1)::after,
    .rtl .footer-bottom-nav ul li:nth-child(2)::after,
    .rtl .footer-bottom-nav ul li:nth-child(5)::after,
    .rtl .footer-bottom-nav ul li:nth-child(7)::after {
        width: 0px;
    }
}

@media screen and (max-width: 834px) {
    .rtl .footer-bottom-nav ul li:not(:last-of-type)::after {
        left: 0;
        right: inherit;
        transform: translate(0, -50%);
    }
}


@media screen and (min-width: 1025px) {
    .footer-bottom-nav {
        padding: 72px 0 24px;
    }

    .footer-bottom-nav ul {
        justify-content: center;
    }
}

.footer-copyright {
    background-color: #000018;
}

.footer-copyright p {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    letter-spacing: 1.5px;
    color: #f5f5f5;
    padding: 24px 0;
}

@media screen and (min-width: 835px) {
    .footer-copyright p {
        text-align: center;
        padding: 18px 0;
    }
}
</style>